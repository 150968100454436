<template>
  <b-modal ref="add-worksheet-modal" hide-footer centered size="lg">
    <div class="d-block py-2 px-1">
      <h4 class="mb-2">{{ modalTitle }}</h4>
      <validation-observer ref="addEditValidation">
        <b-form @submit.prevent>
          <b-row>
            <!-- type -->
            <b-col cols="12">
              <b-form-group label="Select worksheet type">
                <b-form-select
                  v-model="selectedWorksheetType"
                  @change="typeChanged"
                >
                  <b-form-select-option
                    v-for="(worksheetType, index) in worksheetTypes"
                    :value="worksheetType.value"
                    :key="index"
                    :disabled="
                      worksheetType.text != 'Audio' &&
                      worksheetType.text != 'Video' &&
                      worksheetType.text != 'Cue card' &&
                      worksheetType.text != 'Textbox' &&
                      worksheetType.text != 'Document'
                    "
                  >
                    {{ worksheetType.text }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>

            <!-- title -->
            <b-col cols="12">
              <validation-provider
                #default="{ errors }"
                name="title"
                rules="required"
              >
                <b-form-group label="Title">
                  <b-form-input
                    v-model="title"
                    type="text"
                    placeholder="Title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- help -->
            <b-col cols="12" v-if="selectedWorksheetType != 1">
              <b-form-group label="Help">
                <quill-editor v-model="help" />
              </b-form-group>
            </b-col>

            <!-- description -->
            <b-col cols="12" v-if="selectedWorksheetType != 5">
              <b-form-group label="Description">
                <b-form-textarea
                  v-model="description"
                  placeholder="Description"
                  rows="3"
                />
              </b-form-group>
            </b-col>

            <!-- transcript -->
            <b-col
              cols="12"
              v-if="selectedWorksheetType == 2 || selectedWorksheetType == 3"
            >
              <b-form-group label="Transcript">
                <b-form-textarea
                  v-model="transcript"
                  placeholder="Transcript"
                  rows="3"
                />
              </b-form-group>
            </b-col>

            <!-- document link -->
            <b-col cols="12" v-if="selectedWorksheetType == 10">
              <validation-provider
                #default="{ errors }"
                name="Document link"
                rules="required|url"
              >
                <b-form-group label="Link to document">
                  <b-form-input
                    v-model="documentLink"
                    type="text"
                    placeholder="Link to document"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Video file -->
            <b-col cols="12" v-if="selectedWorksheetType == 2">
              
              <b-form-group label="Select video type">
                <b-form-radio-group
                  v-model="selectedVideoType"
                  :options="videoTypeOptions"
                  class="demo-inline-spacing"
                  name="radio-inline"
                />
              </b-form-group>

              <div v-if="selectedVideoType == 'file'">
                <validation-provider
                  v-slot="{ validate, errors }"
                  ref="provider"
                  :rules="
                    modalAction == 'edit' ? 'size:32768' : 'required|size:32768'
                  "
                  name="video file"
                >
                  <b-form-group label="File">
                    <b-form-file
                      type="file"
                      accept=".mp4"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                      @change="
                        handleFileChange($event, 'video') || validate($event)
                      "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </div>

              <div v-if="selectedVideoType == 'youtube'">
                <validation-provider
                  #default="{ errors }"
                  name="Youtube video URL"
                  rules="required|url"
                >
                  <b-form-group label="Youtube URL">
                    <b-form-input
                      v-model="youtubeUrl"
                      type="text"
                      placeholder="Youtube URL"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </div>
              
              <div v-if="selectedVideoType == 'vimeo'">
                <validation-provider
                  #default="{ errors }"
                  name="Vimeo video URL"
                  rules="required|url"
                >
                  <b-form-group label="Vimeo URL">
                    <b-form-input
                      v-model="vimeoUrl"
                      type="text"
                      placeholder="Vimeo URL"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </div>

              <div v-if="modalAction == 'edit' && this.selectedVideoType == 'file'">
                <a :href="fileLink" target="_blank"> Current file link </a>
              </div>
            </b-col>

            <!-- Audio file -->
            <b-col cols="12" v-if="selectedWorksheetType == 3">
              <validation-provider
                v-slot="{ validate, errors }"
                ref="provider"
                :rules="
                  modalAction == 'edit' ? 'size:16384' : 'required|size:16384'
                "
                name="audio file"
              >
                <b-form-group label="File">
                  <b-form-file
                    type="file"
                    accept=".mp3, .wav, .aac"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    @change="
                      handleFileChange($event, 'audio') || validate($event)
                    "
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <div v-if="modalAction == 'edit'">
                <a :href="fileLink" target="_blank"> Current file link </a>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <div class="d-flex justify-content-end align-items-center">
        <div v-if="showUploadSpinner" class="pr-2">
          {{ uploadCompletedPercentage }}% completed
        </div>
        <b-button
          v-if="modalAction == 'add'"
          variant="primary"
          class="my-2 float-right d-flex align-items-center"
          :disabled="showUploadSpinner"
          @click="addWorksheet"
        >
          <b-spinner v-if="showUploadSpinner" small class="mr-1" />
          <span v-if="showUploadSpinner">Uploading...</span>
          <span v-else>Add</span>
        </b-button>
        <b-button
          v-if="modalAction == 'edit'"
          variant="primary"
          class="my-2 float-right"
          :disabled="showUploadSpinner"
          @click="updateWorksheet"
        >
          <b-spinner v-if="showUploadSpinner" small class="mr-1" />
          <span v-if="showUploadSpinner">Uploading...</span>
          <span v-else>Update</span>
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import EventBus from "../../../main.js";
import {
  BModal,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormRadioGroup,
  BFormSelect,
  BFormSelectOption,
  BFormInput,
  BFormFile,
  BFormTextarea,
  BButton,
  VBToggle,
  BCollapse,
  BSpinner,
} from "bootstrap-vue";
import { quillEditor } from "vue-quill-editor";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { size, required } from "@validations";

export default {
  components: {
    BModal,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BFormSelect,
    BFormSelectOption,
    BFormInput,
    BFormFile,
    BFormTextarea,
    BButton,
    VBToggle,
    BCollapse,
    quillEditor,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  data() {
    return {
      modalTitle: "Create new worksheet",
      modalAction: "add",
      worksheetId: "",

      // Fields
      toolkitId: this.$route.params.id,
      selectedWorksheetType: 2,
      worksheetTypes: [],
      title: "",
      documentLink: "",
      order: 1,
      help: "",
      description: "",
      transcript: "",
      file: [],
      fileLink: "",
      required,
      size,
      showUploadSpinner: false,
      uploadCompletedPercentage: "",

      // Video types
      selectedVideoType: 'file',
      videoTypeOptions: [
        { text: 'File', value: 'file' },
        { text: 'Youtube URL', value: 'youtube' },
        { text: 'Vimeo URL', value: 'vimeo' },
      ],
      youtubeUrl: "",
      vimeoUrl: "",
    };
  },
  directives: { "b-toggle": VBToggle },
  methods: {
    async handleFileChange(e, fileType) {
      let fileTypes = [];
      if (fileType == "video") {
        fileTypes = [
          "video/mp4",
          // "video/quicktime",
          // "video/x-ms-wmv",
          // "video/avi",
          // "video/x-flv",
        ];
      } else if (fileType == "audio") {
        fileTypes = ["audio/mpeg", "audio/wav", "audio/vnd.dlna.adts"];
      }

      if (!fileTypes.includes(e.target.files[0].type)) {
        e.target.value = [];
        this.$refs.provider.applyResult({
          errors: [`File must be in ${fileType} format`], // array of string errors
          valid: false, // boolean state
          failedRules: {}, // should be empty since this is a manual error.
        });
      } else {
        const { valid } = await this.$refs.provider.validate(e);
        if (valid) {
          this.file = e.target.files[0];
        }
      }
    },
    getWorksheetTypes() {
      // Request to get worksheet types
      this.$http
        .get("/api/auth/admin/worksheet-types")
        .then((res) => {
          if (res != undefined) {
            let types = [];
            for (let i = 0; i < res.data.length; i++) {
              types.push({ value: res.data[i].id, text: res.data[i].name });
            }

            this.worksheetTypes = types;
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Something went wrong, please login again",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    },
    getWorksheetById(id) {
      // Request to get single worksheet by id
      this.$http
        .get("/api/auth/admin/worksheets/" + id)
        .then((res) => {
          this.worksheetId = id;

          if(res != undefined) {
            this.selectedWorksheetType = res.data.type_id;
            this.title = res.data.text_1;
            this.help =
              this.help == null || this.help == undefined ? "" : res.data.help;
            this.description =
              this.description == null || this.description == undefined
                ? ""
                : res.data.text_2;
            this.transcript =
              this.transcript == null ||
              this.transcript == undefined ||
              res.data.worksheet_content == null
                ? ""
                : res.data.worksheet_content.transcript;
            this.fileLink =
              res.data.worksheet_content == null ||
              res.data.worksheet_content == undefined
                ? ""
                : res.data.worksheet_content.media_url;

            if(res.data.worksheet_content.video_type == 'youtube') {
              this.youtubeUrl =
                res.data.worksheet_content == null ||
                res.data.worksheet_content == undefined
                  ? ""
                  : res.data.worksheet_content.video_link;
            } else {
              this.vimeoUrl =
                res.data.worksheet_content == null ||
                res.data.worksheet_content == undefined
                  ? ""
                  : res.data.worksheet_content.video_link;
            }
            this.selectedVideoType =
              res.data.worksheet_content == null ||
              res.data.worksheet_content == undefined
                ? "file"
                : res.data.worksheet_content.video_type;

            this.documentLink =
              res.data.worksheet_content == null ||
              res.data.worksheet_content == undefined
                ? ""
                : res.data.worksheet_content.document_link;

          }
        })
        .catch((err) => {
          console.log(err.response);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Something went wrong, please login again",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    },
    addWorksheet() {
      this.$refs.addEditValidation.validate().then((success) => {
        if (success) {
          let formData = new FormData();
          formData.append("toolkit_id", this.toolkitId);
          formData.append("type_id", this.selectedWorksheetType);
          formData.append("text_1", this.title);
          formData.append("order", this.order);
          if (this.selectedVideoType == 'file') {
            formData.append("media_file", this.file);
            formData.append("video_type", this.selectedVideoType);
          }
          if (this.selectedVideoType == 'youtube') {
            formData.append("video_link", this.youtubeUrl);
            formData.append("video_type", this.selectedVideoType);
          }
          if (this.selectedVideoType == 'vimeo') {
            formData.append("video_link", this.vimeoUrl);
            formData.append("video_type", this.selectedVideoType);
          }

          // if it's an audio worksheet type
          if(this.selectedWorksheetType == 3) {
            formData.append("media_file", this.file);
          }

          formData.append("text_2", this.description);
          formData.append("help", this.help);
          formData.append("transcript", this.transcript);
          formData.append("_method", "PATCH");

          if(this.selectedWorksheetType == 10) {
            formData.append("document_link", this.documentLink);
          }

          // show spinner
          this.showUploadSpinner = true;

          // Request to create toolkit
          this.$http
            .post("/api/auth/admin/toolkit-worksheets", formData, {
              onUploadProgress: (uploadEvent) => {
                this.uploadCompletedPercentage = Math.round(
                  (uploadEvent.loaded / uploadEvent.total) * 100
                );
              },
            })
            .then((res) => {
              if (res != undefined) {
                EventBus.$emit("getFreshToolkitData");
                // Close modal
                this.$refs["add-worksheet-modal"].hide();
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Worksheet added successfully",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });

              this.resetFields()

              // hide spinner
              this.showUploadSpinner = false;
            })
            .catch((err) => {
              console.log(err);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Something went wrong, please login again",
                  icon: "AlertCircleIcon",
                  variant: "danger",
                },
              });

              // hide spinner
              this.showUploadSpinner = false;
            });
        }
      });
    },
    updateWorksheet() {
      this.$refs.addEditValidation.validate().then((success) => {
        if (success) {
          if (this.help == null || this.help == undefined) {
            this.help = "";
          }
          if (this.description == null || this.description == undefined) {
            this.description = "";
          }
          if (this.transcript == null || this.transcript == undefined) {
            this.transcript = "";
          }

          let formData = new FormData();

          formData.append("toolkit_id", this.toolkitId);
          formData.append("type_id", this.selectedWorksheetType);
          formData.append("text_1", this.title);
          formData.append("order", this.order);
          if (this.selectedVideoType == 'file' && this.file.length == undefined) {
            formData.append("media_file", this.file);
          }
          if ((this.selectedVideoType == 'youtube') && this.youtubeUrl.length > 0) {
            formData.append("video_link", this.youtubeUrl);
          }
          if ((this.selectedVideoType == 'vimeo') && this.vimeoUrl.length > 0) {
            formData.append("video_link", this.vimeoUrl);
          }

          if(this.selectedVideoType == 'vimeo' || this.selectedVideoType == 'youtube' || this.selectedVideoType == 'file') {
            formData.append("video_type", this.selectedVideoType);
          }

          // if it's an audio worksheet type
          if(this.selectedWorksheetType == 3) {
            formData.append("media_file", this.file);
          }

          formData.append("text_2", this.description);
          formData.append("help", this.help);
          formData.append("transcript", this.transcript);

          formData.append("_method", "PATCH");
          
          if(this.selectedWorksheetType == 10) {
            formData.append("document_link", this.documentLink);
          }

          // show spinner
          this.showUploadSpinner = true;

          // Request to create toolkit
          this.$http
            .post("/api/auth/admin/worksheets/" + this.worksheetId, formData, {
              onUploadProgress: (uploadEvent) => {
                this.uploadCompletedPercentage = Math.round(
                  (uploadEvent.loaded / uploadEvent.total) * 100
                );
              },
            })
            .then((res) => {
              if (res != undefined) {
                EventBus.$emit("getFreshToolkitData");
                // Close modal
                this.$refs["add-worksheet-modal"].hide();
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Worksheet edited successfully",
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });

                this.resetFields()

                // hide spinner
                this.showUploadSpinner = false;
              }
            })
            .catch((err) => {
              console.log(err);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Something went wrong, please login again",
                  icon: "AlertCircleIcon",
                  variant: "danger",
                },
              });

              // hide spinner
              this.showUploadSpinner = false;
            });
        }
      });
    },
    typeChanged() {
      this.title = "";
      this.order = 1;
      this.help = "";
      this.description = "";
      this.transcript = "";
      this.file = [];
      this.documentLink = "";
      // reseting validation
      this.$nextTick(() => this.$refs.addEditValidation.reset());
    },
    resetFields() {
      this.worksheetId = "";
      this.selectedWorksheetType = 2;
      this.title = "";
      this.help = "";
      this.description = "";
      this.transcript = "";
      this.file = [];
      this.youtubeUrl = "";
      this.vimeoUrl = "";
      this.documentLink = "";
    }
  },
  mounted() {
    this.getWorksheetTypes();

    EventBus.$on("openAddWorksheetModal", (id) => {
      if (id != undefined) {
        this.getWorksheetById(id);

        this.modalTitle = "Update worksheet";
        this.modalAction = "edit";
      } else {
        this.modalTitle = "Create new worksheet";
        this.modalAction = "add";
        this.selectedVideoType = "file"

        // Reset fields
        this.resetFields()
      }

      if (this.$refs["add-worksheet-modal"] != undefined) {
        this.$refs["add-worksheet-modal"].show();
      }
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>
