<template>
    <div>
        <div
            class="
            d-flex
            flex-wrap
            justify-content-between
            align-items-center
            "
        >
            <div class="d-flex">
                <b-avatar
                    variant="primary"
                    v-if="worksheet.type.name == 'Video'"
                >
                    <feather-icon icon="VideoIcon" />
                </b-avatar>
                <b-avatar
                    variant="danger"
                    v-else-if="worksheet.type.name == 'Audio'"
                >
                    <feather-icon icon="MusicIcon" />
                </b-avatar>
                <b-avatar
                    variant="info"
                    v-else-if="worksheet.type.name == 'Cue card'"
                >
                    <feather-icon icon="Edit3Icon" />
                </b-avatar>
                <b-avatar
                    variant="warning"
                    v-else-if="worksheet.type.name == 'Textbox'"
                >
                    <feather-icon icon="EditIcon" />
                </b-avatar>
                <b-avatar
                    variant="success"
                    v-else-if="worksheet.type.name == 'Document'"
                >
                    <feather-icon icon="FileTextIcon" />
                </b-avatar>

                <div class="ml-1">
                    <b-card-text class="font-weight-bold mb-0">
                    {{ worksheet.text_1.split(" ").slice(0, 3).join(" ") }}
                    <span v-if="worksheet.text_1.split(' ').length > 3"
                        >...</span
                    >
                    </b-card-text>
                    <div v-if="worksheet.text_2">
                    <small>{{
                        worksheet.text_2.split(" ").slice(0, 4).join(" ")
                    }}</small>
                    <small v-if="worksheet.text_2.split(' ').length > 4"
                        >...</small
                    >
                    </div>
                </div>
            </div>

            <div class="d-flex">
                <div class="cursor-pointer">
                    <b-badge
                    variant="light-primary"
                    @click="previewWorksheet()"
                    v-b-tooltip.hover.top="'Preview worksheet'"
                    >
                        <feather-icon icon="EyeIcon" />
                    </b-badge>
                </div>
                <div class="cursor-pointer">
                    <b-badge
                    variant="light-primary ml-25"
                    @click="updateWorksheet(worksheet.id)"
                    v-b-tooltip.hover.top="'Edit worksheet'"
                    >
                        <feather-icon icon="EditIcon" />
                    </b-badge>
                </div>
                <div
                    class="cursor-pointer"
                    @click="removeWorksheet(worksheet.id)"
                >
                    <b-badge
                    variant="light-danger ml-25"
                    v-b-tooltip.hover.top="'Remove worksheet'"
                    >
                        <feather-icon icon="Trash2Icon" />
                    </b-badge>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from '../../main.js'
import {
  BCardText,
  BAvatar,
  BBadge,
  VBTooltip,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        BCardText,
        BAvatar,
        BBadge,
    },
    props: {
        worksheet: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        total: {
            type: Number,
            required: true
        }
    },
    directives: {
        "b-tooltip": VBTooltip,
    },
    methods: {
        previewWorksheet(id) {
            this.$store.dispatch("toolkitStoreModule/setWorksheetIndex", this.index)

            // Update toolkit progress
            this.$store.dispatch("toolkitStoreModule/setToolkitProgress", (this.index / this.total) * 100);

            this.$router.push({
                name: "learn-and-develop-preview",
                params: { id: this.$route.params.id },
            });
        },
        updateWorksheet(id) {
            EventBus.$emit("openAddWorksheetModal", id);
        },
        removeWorksheet(id) {
            this.$bvModal.msgBoxConfirm("This worksheet will be removed", {
                title: "Are your sure?",
                size: "sm",
                okVariant: "primary",
                okTitle: "Yes",
                cancelTitle: "No",
                cancelVariant: "outline-secondary",
                hideHeaderClose: false,
                centered: true,
            })
            .then((value) => {
                if (value == true) {
                    this.$http.delete("/api/auth/admin/worksheets/" + id)
                    .then((response) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "The worksheet was successfully removed!",
                                icon: "CheckSquareIcon",
                                variant: "success",
                            },
                        });
                        
                        // Get fresh toolkit data
                        EventBus.$emit('getFreshToolkitData')
                    })
                    .catch((error) => {
                        console.log(error)
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "There was an error removing worksheet",
                                icon: "AlertCircleIcon",
                                variant: "danger",
                            },
                        });
                    });
                }
            });
        },
    }
}
</script>