<template>
  <b-card-code no-body title="Update Learn and Develop toolkit">
    <b-card-body>
      <b-row class="match-height">
        <b-col md="6">
          <validation-observer ref="updateLearnandDevelopToolkit">
            <div class="mt-1">
              <label>Title</label>
              <validation-provider
                #default="{ errors }"
                name="title"
                rules="required"
              >
                <b-form-input v-model="title" type="text" placeholder="Title" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>

            <div class="mt-1">
              <label>Category</label>
              <validation-provider
                #default="{ errors }"
                name="category"
                rules="required"
              >
                <b-form-select
                  v-model="category"
                  :options="categoryOptions"
                  class="w-100"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>

            <div class="mt-1">
              <label>Description</label>
              <validation-provider
                #default="{ errors }"
                name="description"
                :rules="{
                  required: true,
                }"
              >
                <b-form-textarea
                  v-model="description"
                  placeholder="Description"
                  rows="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>

            <div class="mt-1">
              <label>Image</label>
              <validation-provider
                v-slot="{ validate, errors }"
                ref="provider"
                name="image file"
                rules="size:4096"
              >
                <b-form-file
                  accept=".jpg, .png, .jpeg"
                  placeholder="Choose an image or drop it here..."
                  drop-placeholder="Drop file here..."
                  @change="handleFileChange($event) || validate($event)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>

            <div class="mt-1">
              <b-img
                fluid
                :src="image_url"
                class="image-preview"
              />
            </div>
          </validation-observer>

          <div class="mt-1">
            <label>Created by</label>
            <b-form-input v-model="author" maxlength="50" type="text" placeholder="Created by" />
          </div>

          <div class="mt-1">
            <label>About the Author</label>
            <b-form-textarea
                v-model="about_author"
                placeholder="About the Author"
                rows="3"
            />
          </div>

          <div class="mt-1">
            <label class="mb-1">Recommended questionnaires</label>
            <v-select
              v-model="selectedQuestionnaire"
              multiple
              label="title"
              :options="questionnaires"
            />
          </div>

          <div class="mt-1">
            <label class="mb-1">Select professional</label>
            <b-form-select
              v-model="selectedProfessional"
              :options="optionsProfessional"
            ></b-form-select>
          </div>

        </b-col>

        <b-col md="6">
          <div class="d-flex justify-content-between mb-2">
            <div>
              <b-card-text>
                <span>Worksheets:</span>
              </b-card-text>
            </div>
            <div>
              <b-button
                variant="secondary"
                class="ml-auto"
                @click="addWorksheet"
                >Add worksheet</b-button
              >
            </div>
          </div>

          <!-- draggable -->
          <draggable
            v-model="worksheetList"
            v-if="worksheetList.length > 0"
            class="list-group list-group-flush cursor-move"
            tag="ul"
          >
            <transition-group type="transition" name="flip-list">
              <b-list-group-item
                v-for="(worksheet, index) in worksheetList"
                :key="index"
                tag="li"
              >
                <draggable-worksheet-item :worksheet="worksheet" :index="index" :total="worksheetList.length" />
              </b-list-group-item>
            </transition-group>
          </draggable>

          <add-edit-worksheet :v-show="true" />
        </b-col>
      </b-row>

      <div class="d-flex mt-2 justify-content-end">
        <b-button variant="secondary" class="mr-25" @click="previewToolkit"
          >Preview</b-button
        >
        <b-button
          variant="secondary"
          class="mr-25"
          v-if="published"
          @click="changeToolkitStatus"
          >Unpublish</b-button
        >
        <b-button class="mr-25 bg-mhc-dark" v-else @click="changeToolkitStatus"
          >Publish</b-button
        >
        <b-button
          variant="primary"
          :disabled="showUploadSpinner"
          @click="updateToolkit(true)"
        >
          <b-spinner v-if="showUploadSpinner" small class="mr-1" />
          <span v-if="showUploadSpinner">Updating...</span>
          <span v-else>Update</span>
        </b-button>
      </div>
    </b-card-body>
  </b-card-code>
</template>

<script>
import EventBus from "../../main.js";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BCardBody,
  BCardText,
  BFormFile,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BImg,
  BRow,
  BCol,
  BButton,
  BListGroupItem,
  BAvatar,
  BBadge,
  BSpinner,
} from "bootstrap-vue";
import vSelect from 'vue-select'
import draggable from "vuedraggable";
import AddEditWorksheet from "../../components/modals/toolkit/AddEditWorksheet.vue";
import DraggableWorksheetItem from "../../components/toolkit/DraggableWorksheetItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { size, required } from "@validations";

export default {
  components: {
    BCardCode,
    BCardBody,
    BCardText,
    BFormFile,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BImg,
    BRow,
    BCol,
    BButton,
    draggable,
    BListGroupItem,
    BAvatar,
    BBadge,
    AddEditWorksheet,
    DraggableWorksheetItem,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    vSelect,
  },
  data() {
    return {
      toolkitId: this.$route.params.id,
      title: "",
      category: "",
      categoryOptions: [],
      description: "",
      worksheetList: [],
      published: null,
      image_url: "",
      required,
      size,
      image: [],
      author: "",
      about_author: "",
      showUploadSpinner: false,

      // Recommended questionnaires
      selectedQuestionnaire: [],
      questionnaires: [],

      // Professional profiles
      selectedProfessional: null,
      optionsProfessional: [],
    };
  },
  methods: {
    async handleFileChange(e) {
      if (
        e.target.files[0].type !== "image/jpeg" &&
        e.target.files[0].type !== "image/png"
      ) {
        e.target.value = [];
        this.$refs.provider.applyResult({
          errors: ["Image must be in .jpg, .jpeg or .png format"], // array of string errors
          valid: false, // boolean state
          failedRules: {}, // should be empty since this is a manual error.
        });
      } else {
        const { valid } = await this.$refs.provider.validate(e);
        if (valid) {
          // Set image file
          this.image = e.target.files[0]

          // Create base64 from file - connected with image_url
          this.createImage(e.target.files[0]);
        }
      }
    },
    getToolkit() {
      // Request to create toolkit
      this.$http
        .get("/api/auth/admin/toolkits/" + this.$route.params.id)
        .then((res) => {
          this.title = res.data.title;
          this.category = res.data.category.id;
          this.description = res.data.description;
          this.worksheetList = res.data.worksheets;
          this.published = res.data.published;
          this.image_url = res.data.image_url;
          this.author = res.data.author;
          this.about_author = res.data.about_author;
          this.selectedQuestionnaire = res.data.recommended_questionnaires;
          this.selectedProfessional = res.data.professional_id;
        })
        .catch((err) => {
          // If toolkit doesn't exist redirect user to create page
          if (err.response.status == 404) {
            this.$router.push({ name: "learn-and-develop-create" });
          } else {
            console.log(err);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong, please login again",
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
        });
    },
    getAllToolkitCategories() {
      this.$http.get("/api/auth/toolkit-categories")
        .then((res) => {
          for(let i=0; i<res.data.length; i++) {
            // Push categires direct to categoryOptions
            this.categoryOptions.push({ value: res.data[i].id, text: res.data[i].name })
          }
        })
        .catch((err) => {
            console.log(err);
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Something went wrong. Please login again",
                    icon: "AlertCircleIcon",
                    variant: "danger",
                },
            });
        })
    },
    getAllQuestionnaires() {
      this.$http.get("/api/auth/admin/questionnaire")
        .then((res) => {
          for(let i=0; i<res.data.length; i++) {
            // Push questionnaires direct to questionnaires
            this.questionnaires.push({ title: res.data[i].title, id: res.data[i].id })
          }
        })
        .catch((err) => {
            console.log(err);
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Something went wrong. Please login again",
                    icon: "AlertCircleIcon",
                    variant: "danger",
                },
            });
        })
    },
    updateToolkit(showToast) {
      this.$refs.updateLearnandDevelopToolkit.validate().then((success) => {
        if (success) {

          let questionnaireIds = [];

          for (let i = 0; i < this.selectedQuestionnaire.length; i++) {
            questionnaireIds.push(this.selectedQuestionnaire[i].id);            
          }

          let formData = new FormData();
          formData.append("title", this.title);
          formData.append("category", this.category);
          formData.append("description", this.description);
          // If image added to field
          if (this.image != undefined && this.image !== []) {
            formData.append("image", this.image);
          }
          formData.append("worksheets", JSON.stringify(this.worksheetList));
          formData.append("author", this.author);
          formData.append("about_author", this.about_author);
          formData.append("recommended_questionnaires", JSON.stringify(questionnaireIds));
          if(this.selectedProfessional != null) {
            formData.append("professional_id", this.selectedProfessional);
          }
          formData.append("_method", "PATCH");

          // show spinner
          this.showUploadSpinner = true;

          // Request to update toolkit
          this.$http
            .post("/api/auth/admin/toolkits/" + this.toolkitId, formData)
            .then((res) => {
              if (res != undefined) {
                if(showToast == true) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "The toolkit was successfully updated",
                      icon: "CheckSquareIcon",
                      variant: "success",
                    },
                  });
                }
              }

              // hide spinner
              this.showUploadSpinner = false;
            })
            .catch((err) => {
              console.log(err);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Something went wrong, please login again",
                  icon: "AlertCircleIcon",
                  variant: "danger",
                },
              });

              // hide spinner
              this.showUploadSpinner = false;
            });
        }
      });
    },
    addWorksheet() {
      EventBus.$emit("openAddWorksheetModal");
    },
    changeToolkitStatus() {
      // If user want to publish toolkit
      if(this.published == 0) {
        // Check is empty list of worksheets
        if(this.worksheetList.length == 0) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Please create at least one worksheet",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });

          return;
        }
      }

      // Publish/unpublish toolkit
      let status = {
        published: !this.published,
      };

      let message = "";
      if (this.published) {
        message = "This toolkit will be unpublished";
      } else {
        message = "This toolkit will be published";
      }

      this.$bvModal
        .msgBoxConfirm(message, {
          title: "Are your sure?",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.$http
              .patch(
                "/api/auth/admin/toolkits/status/" + this.toolkitId,
                status
              )
              .then((res) => {
                if (res != undefined) {
                  this.getToolkit();
                }
              })
              .catch((err) => {
                console.log(err);
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Something went wrong, please login again",
                    icon: "AlertCircleIcon",
                    variant: "danger",
                  },
                });
              });
          }
        });
    },
    previewToolkit() {
      // Start from first worksheet
      this.$store.dispatch("toolkitStoreModule/setWorksheetIndex", 0)

      // Reset toolkit progress
      this.$store.dispatch("toolkitStoreModule/setToolkitProgress", 0);

      // Update toolkit because we need the newest order
      this.updateToolkit(false) 

      // Open preview view
      this.$router.push({
        name: "learn-and-develop-preview",
        params: { id: this.$route.params.id },
      });
    },
    createImage(file) {
      let reader = new FileReader();
      let vm = this;

      reader.onload = (e) => {
        vm.image_url = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    getProfessionalProfiles() {
      // Get professionals
      this.$http.get("/api/auth/admin/professionals?listAll=true")
        .then((res) => {
          if (res != undefined) {

            let professionals = [{
              value: null,
              text: 'None'
            }]

            for (let i = 0; i < res.data.length; i++) {
              professionals.push({
                value: res.data[i].id,
                text: res.data[i].first_name + ' ' + res.data[i].last_name + ' - ' + res.data[i].qualifications,
              });
            }

            this.optionsProfessional = professionals;
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Something went wrong, please login again",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    },
  },
  mounted() {
    this.getToolkit();
    this.getAllToolkitCategories();
    this.getAllQuestionnaires();
    this.getProfessionalProfiles();

    EventBus.$on("getFreshToolkitData", () => {
      this.getToolkit();
    });
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>

<style>
.vs__selected {
  background-color: var(--mhc-dark);
}

.vs__dropdown-option--selected {
  background-color: var(--mhc-dark);
  color: #fff;
}

.vs__dropdown-option.vs__dropdown-option--selected.vs__dropdown-option--highlight {
  background-color: var(--mhc-light)!important;
  color: #fff;
}

.vs__dropdown-option--highlight {
  background-color: #d0e2e440!important;
  color: #6e6b7b!important;
}

.list-group-item {
  transition: all 1s;
}

.image-preview {
  max-width: 150px;
  max-height: 150px;
}
</style>