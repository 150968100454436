var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"add-worksheet-modal",attrs:{"hide-footer":"","centered":"","size":"lg"}},[_c('div',{staticClass:"d-block py-2 px-1"},[_c('h4',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.modalTitle))]),_c('validation-observer',{ref:"addEditValidation"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Select worksheet type"}},[_c('b-form-select',{on:{"change":_vm.typeChanged},model:{value:(_vm.selectedWorksheetType),callback:function ($$v) {_vm.selectedWorksheetType=$$v},expression:"selectedWorksheetType"}},_vm._l((_vm.worksheetTypes),function(worksheetType,index){return _c('b-form-select-option',{key:index,attrs:{"value":worksheetType.value,"disabled":worksheetType.text != 'Audio' &&
                    worksheetType.text != 'Video' &&
                    worksheetType.text != 'Cue card' &&
                    worksheetType.text != 'Textbox' &&
                    worksheetType.text != 'Document'}},[_vm._v(" "+_vm._s(worksheetType.text)+" ")])}),1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Title"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Title"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),(_vm.selectedWorksheetType != 1)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Help"}},[_c('quill-editor',{model:{value:(_vm.help),callback:function ($$v) {_vm.help=$$v},expression:"help"}})],1)],1):_vm._e(),(_vm.selectedWorksheetType != 5)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Description"}},[_c('b-form-textarea',{attrs:{"placeholder":"Description","rows":"3"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1):_vm._e(),(_vm.selectedWorksheetType == 2 || _vm.selectedWorksheetType == 3)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Transcript"}},[_c('b-form-textarea',{attrs:{"placeholder":"Transcript","rows":"3"},model:{value:(_vm.transcript),callback:function ($$v) {_vm.transcript=$$v},expression:"transcript"}})],1)],1):_vm._e(),(_vm.selectedWorksheetType == 10)?_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Document link","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Link to document"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Link to document"},model:{value:(_vm.documentLink),callback:function ($$v) {_vm.documentLink=$$v},expression:"documentLink"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1871925605)})],1):_vm._e(),(_vm.selectedWorksheetType == 2)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Select video type"}},[_c('b-form-radio-group',{staticClass:"demo-inline-spacing",attrs:{"options":_vm.videoTypeOptions,"name":"radio-inline"},model:{value:(_vm.selectedVideoType),callback:function ($$v) {_vm.selectedVideoType=$$v},expression:"selectedVideoType"}})],1),(_vm.selectedVideoType == 'file')?_c('div',[_c('validation-provider',{ref:"provider",attrs:{"rules":_vm.modalAction == 'edit' ? 'size:32768' : 'required|size:32768',"name":"video file"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var validate = ref.validate;
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"File"}},[_c('b-form-file',{attrs:{"type":"file","accept":".mp4","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},on:{"change":function($event){_vm.handleFileChange($event, 'video') || validate($event)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3596605004)})],1):_vm._e(),(_vm.selectedVideoType == 'youtube')?_c('div',[_c('validation-provider',{attrs:{"name":"Youtube video URL","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Youtube URL"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Youtube URL"},model:{value:(_vm.youtubeUrl),callback:function ($$v) {_vm.youtubeUrl=$$v},expression:"youtubeUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3433535364)})],1):_vm._e(),(_vm.selectedVideoType == 'vimeo')?_c('div',[_c('validation-provider',{attrs:{"name":"Vimeo video URL","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Vimeo URL"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Vimeo URL"},model:{value:(_vm.vimeoUrl),callback:function ($$v) {_vm.vimeoUrl=$$v},expression:"vimeoUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,643572793)})],1):_vm._e(),(_vm.modalAction == 'edit' && this.selectedVideoType == 'file')?_c('div',[_c('a',{attrs:{"href":_vm.fileLink,"target":"_blank"}},[_vm._v(" Current file link ")])]):_vm._e()],1):_vm._e(),(_vm.selectedWorksheetType == 3)?_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{ref:"provider",attrs:{"rules":_vm.modalAction == 'edit' ? 'size:16384' : 'required|size:16384',"name":"audio file"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var validate = ref.validate;
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"File"}},[_c('b-form-file',{attrs:{"type":"file","accept":".mp3, .wav, .aac","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},on:{"change":function($event){_vm.handleFileChange($event, 'audio') || validate($event)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3486935919)}),(_vm.modalAction == 'edit')?_c('div',[_c('a',{attrs:{"href":_vm.fileLink,"target":"_blank"}},[_vm._v(" Current file link ")])]):_vm._e()],1):_vm._e()],1)],1)],1),_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[(_vm.showUploadSpinner)?_c('div',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(_vm.uploadCompletedPercentage)+"% completed ")]):_vm._e(),(_vm.modalAction == 'add')?_c('b-button',{staticClass:"my-2 float-right d-flex align-items-center",attrs:{"variant":"primary","disabled":_vm.showUploadSpinner},on:{"click":_vm.addWorksheet}},[(_vm.showUploadSpinner)?_c('b-spinner',{staticClass:"mr-1",attrs:{"small":""}}):_vm._e(),(_vm.showUploadSpinner)?_c('span',[_vm._v("Uploading...")]):_c('span',[_vm._v("Add")])],1):_vm._e(),(_vm.modalAction == 'edit')?_c('b-button',{staticClass:"my-2 float-right",attrs:{"variant":"primary","disabled":_vm.showUploadSpinner},on:{"click":_vm.updateWorksheet}},[(_vm.showUploadSpinner)?_c('b-spinner',{staticClass:"mr-1",attrs:{"small":""}}):_vm._e(),(_vm.showUploadSpinner)?_c('span',[_vm._v("Uploading...")]):_c('span',[_vm._v("Update")])],1):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }